/**
 * @license
 * MyFonts Webfont Build ID 3430004, 2017-07-31T12:26:42-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: UniversLTPro-55Roman by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-55-roman/
 * Copyright: Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted &#x00A9; 2007 Linotype GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype GmbH, and may not be reproduced,
 * Licensed pageviews: 250,000
 *
 * Webfont: UniversLTPro-55Oblique by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-55-oblique/
 * Copyright: Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted &#x00A9; 2007 Linotype GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype GmbH, and may not be reproduced,
 * Licensed pageviews: 500,000
 *
 * Webfont: UniversLTPro-65Bold by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-65-bold/
 * Copyright: Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted &#x00A9; 2007 Linotype GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype GmbH, and may not be reproduced,
 * Licensed pageviews: 500,000
 *
 * Webfont: SabonLTPro-Italic by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/sabon/pro-italic/
 * Copyright: Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted &#x00A9; 2008 Linotype GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype GmbH, and may not be reproduced,
 * Licensed pageviews: 500,000
 *
 * Webfont: SabonLTPro-Bold by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/sabon/pro-bold/
 * Copyright: Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted &#x00A9; 2008 Linotype GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype GmbH, and may not be reproduced,
 * Licensed pageviews: 500,000
 *
 * Webfont: SabonLTPro-Roman by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/sabon/pro-roman/
 * Copyright: Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted &#x00A9; 2008 Linotype GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype GmbH, and may not be reproduced,
 * Licensed pageviews: 250,000
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3430004
 *
 * © 2017 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/345674")

@font-face {font-family: 'UniversLTPro-55Roman'; src: url('/static/fonts/UniversLTPro-55Roman.otf') format('opentype');}

@font-face {font-family: 'UniversLTPro-55Oblique'; src: url('/static/fonts/UniversLTPro-55Oblique.otf') format('opentype');}

@font-face {font-family: 'UniversLTPro-65Bold'; src: url('/static/fonts/UniversLTPro-65Bold.otf') format('opentype');}

@font-face {font-family: 'SabonLTPro-Italic'; src: url('/static/fonts/SabonLTPro-Italic.otf') format('opentype');}

@font-face {font-family: 'SabonLTPro-Bold'; src: url('/static/fonts/SabonLTPro-Bold.otf') format('opentype');}

@font-face {font-family: 'SabonLTPro-Roman'; src: url('/static/fonts/SabonLTPro-Roman.otf') format('opentype');}
